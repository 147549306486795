<template>
  <div>
    <timed-exam v-if="type === 'time'"
                :mode="mode"
                :questions="questions"
                :total-time="time"
                :lab="lab"
                :rules="rules"
                @changeMode="changeMode($event)"
    ></timed-exam>

    <un-timed-exam
        v-if="type === 'liveTutor'"
        :mode="mode"
        :questions="questions"
        :lab="lab"
        :rules="rules"
        @changeMode="changeMode($event)"
    ></un-timed-exam>

    <tutor-exam
        v-if="type === 'tutor'"
        :mode="mode"
        :questions="questions"
        :total-time="time"
        :lab="lab"
        :rules="rules"
        @changeMode="changeMode($event)"
    ></tutor-exam>
    <div class="text-center">
      <!--      FINISH EXAM-->
      <v-dialog
          v-model="endDialog"
          width="800px"
      >
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" cols="10">
            <v-card color="#F2F3F7" class="pa-4" style="border-radius: 10px!important;">
              <v-card-text class="text-top-question"
                           style="border-color:#A8A8A8;border-style: solid;border-radius: 10px;border-width:1px!important;padding:1.9vw!important;">
                <v-row>
                  <v-col>
                    Are you sure? <br>
                    This will end the test and you can not change your answers after that!
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn @click="endTest()" elevation="0" class="white--text" rounded width="220px" height="60px"
                           color="#48B284">
                          <span>
                            Yes
                          </span>
                      <span class="text-capitalize">
                            ,end the test!
                          </span>
                    </v-btn>
                  </v-col>
                  <v-col class="text-left">
                    <v-btn @click="endDialog = false" elevation="0" class="white--text" rounded width="220px"
                           height="60px" color="#FDBD68">
                          <span>
                            NO
                          </span>
                      <span class="text-capitalize">
                            ,return to test!
                          </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
            <img style="cursor: pointer" @click="endDialog = false" src="../assets/close.svg" alt="close" width="50px"
                 height="50px">
          </v-col>
        </v-row>
      </v-dialog>
      <!--      Comment-->
      <v-dialog
          v-model="commentDialog"
          width="800px"
      >
        <v-row class="ma-0 pa-0">
          <v-col class="ma-0 pa-0" cols="10">
            <v-card color="#F2F3F7" class="pa-4" style="border-radius: 10px!important;">
              <v-card-text class="text-top-question">
                <v-row>
                  <v-col>
                    <v-text-field
                        color="#39bf88"
                        label="Title"
                        v-model="title">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                        v-model="body"
                        label="Comment"
                        outlined
                        color="#39bf88"
                        rows="10">
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn class="text-capitalize mx-8" style="color:white;font-size:16px; box-shadow: none!important;"
                           height="60px" width="210px" rounded color="#fdbd68" @click="commentDialog = false">Cancel
                    </v-btn>
                    <v-btn class="text-capitalize mx-8" style="color:white;font-size:16px; box-shadow: none!important;"
                           elevation="0" height="60px" width="210px" rounded color="#39bf88" @click="storeComment()">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
            <img style="cursor: pointer" @click="commentDialog = false" src="../assets/close.svg" alt="close"
                 width="50px"
                 height="50px">
          </v-col>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>
<script>

// import SideBar from "@/components/SideBar";
// import Report from "../components/Report";


import TimedExam from "../components/exam/questions/TimedExam";
import UnTimedExam from "../components/exam/questions/UnTimedExam";
import TutorExam from "../components/exam/questions/TutorExam";

export default {
  name: "Question",
  components: {TutorExam, UnTimedExam, TimedExam},
  data() {
    return {
      type: null,
      mode: 'BAR',


      alertAnswer: false,
      showSidebar: true,
      fullscreen: false,
      msg: '',
      snackbar: false,
      endDialog: false,
      commentDialog: false,
      rules: null,
      lab: null,
      selectedQuestion: null,
      questionsAndAnswers: {},
      questionsCondition: {},
      showLab: false,
      second: null,
      questions: null,
      time: [],
      examFinished: false,
      title: null,
      body: null,
    }
  },
  methods: {
    setOffAlert() {
      var self = this
      const timeOut = setTimeout(() => {
        self.alertAnswer = false
        clearTimeout(timeOut);
      }, 4000)
    },
    onSubmit() {
      this.alertAnswer = true
      this.setOffAlert()
    },
    bookmark(type) {
      window.axios.get('api/exam/mark/' + type + '/' + this.questions[this.selectedQuestion - 1].id).then(() => {
        if (type === 'mark') {
          this.questions[this.selectedQuestion - 1].bookmark = true
        }
        if (type === 'unmark') {
          this.questions[this.selectedQuestion - 1].bookmark = false
        }
      })
    },
    toggleDrawer() {
      this.$refs.sidebarComponent.toggleDrawer()
    },
    changeMode(mode) {
      this.mode = mode
    },
    endTest() {
      this.storeExam()
      this.$router.push('/questions/finish')
    },
    storeExam() {
      window.axios.post('api/exam/store', {
        type: this.rules.type,
        questions: this.questions.length,
        questionsAndAnswers: this.questionsAndAnswers
      }).then((res) => {
        console.log(res.status)
      })
    },
    storeComment() {
      window.axios.post('api/exam/comment', {
        title: this.title,
        body: this.body,
        questionId: this.selectedQuestion
      }).then(() => {
        this.msg = 'Your comment  on "#' + this.questions[this.selectedQuestion - 1].id + '" question stored successfully'
        this.commentDialog = false
        this.snackbar = true
      }).catch(() => {
        this.msg = 'Your comment does not stored check inputs'
        this.commentDialog = false
        this.snackbar = true
      })
    },
    setAnswer(answer) {
      this.$set(this.questionsAndAnswers, this.questions[this.selectedQuestion - 1].id, answer.id)
      window.axios.post('/api/exam/answers', {
        answer: {
          selected_box: answer.id,
          question_id: this.questions[this.selectedQuestion - 1].id,
        }
      }).then((res) => {
        if (res.data.data.condition) {
          this.$set(this.questionsCondition, this.questions[this.selectedQuestion - 1].id, 'true')
        } else {
          this.$set(this.questionsCondition, this.questions[this.selectedQuestion - 1].id, 'false')
        }
      })
    }
  },
  mounted() {
    window.axios.get('api/exam/get').then((res) => {
      this.type = res.data.data.rules.type
      this.questions = res.data.data.questions
      this.selectedQuestion = 1;
      this.rules = res.data.data.rules
      this.lab = res.data.data.lab
      this.time['minutes'] = parseInt(this.rules.examTime / 60)
      this.time['seconds'] = this.rules.examTime - (this.time['minutes'] * 60)

    })
  },

}
</script>

<style>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.v-dialog {
  box-shadow: 0px 0px 0px 0px !important;
}

.v-dialog--active {
  box-shadow: unset !important;
}

.background-main {
  background-color: white !important;
}

.background-top-question-bar-closed {
  height: 120px !important;
  position: absolute;
  left: 0 !important;
  background-color: #f2f3f7;
}

.background-top-question-bar-opened {
  top: 0 !important;
  height: 120px !important;
  background-color: #f2f3f7;
}

.background-top-question-bar-nbme-opened {
  top: 0 !important;
  height: 120px !important;
  background-color: #3C549F;
}

.background-top-question-bar-nbme-closed {
  height: 120px !important;
  position: absolute;
  left: 0 !important;
  background-color: #3C549F;
}

.text-top-question {
  font-size: 16px;
  color: #A8A8A8;
}

.text-top-question-nbme {
  font-size: 16px;
  color: white;
}

.margin-top-negative {
  margin-top: -10px !important;
}

.sidebar-question {
  background-color: #F2F3F7;
  box-shadow: 1px 0 10px 4px rgba(168, 168, 168, 0.5);
  height: 100vh;
  width: 100px !important;
  left: 0 !important;
  top: 0 !important;
  z-index: 2;
  position: absolute !important;
  max-height: 100vw;
  overflow-y: scroll;
}

.footer-question {
  position: absolute !important;
  bottom: 0px !important;
  height: 120px;
  background-color: #F2F3F7;
}

.footer-question-nbme {
  position: absolute !important;
  bottom: 0px !important;
  height: 120px;
  background-color: #3C549F;
}

.counter-question-btn {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px;
  color: white;
}

.counter-question-btn:hover {
  background: #ffffff !important;
}

.question-title-text {
  font-size: 16px;
  font-family: Blinker-Regular, serif !important;
  color: #444444;
}

.lab-container-bar {
  margin-top: 5vh !important;
  font-size: 16px !important;
  background-color: #F2F3F7 !important;
  height: 500px;
  overflow: scroll;
  width: 520px !important;
  border-radius: 15px !important
}

.lab-container-nbme {
  font-size: 16px !important;
  background-color: #F2F3F7 !important;
  height: 500px;
  overflow: scroll;
  width: 520px !important;
  border-radius: 15px !important
}

.background-lab-bar {
  margin-top: 7vh;
  padding-top: 30px;
  height: 60vh;
  overflow: scroll;
  width: 520px !important;
  border-radius: 15px !important;
}

.background-lab-container-bar {
  font-size: 16px !important;
  background-color: #F2F3F7 !important;
  border-radius: 15px !important;
}

.background-lab-nbme {
  margin-top: 0.9vh;
  padding-top: 30px;
  height: 60vh;
  overflow: scroll;
  width: 520px !important;
  border-radius: 15px !important;
}

.background-lab-container-nbme {
  font-size: 16px !important;
  background-color: #F2F3F7 !important;
  border-radius: 15px !important;
}

.button-hover:hover {
  border-radius: 10px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #808183 !important;
}

.question-container-custom-opened {
  margin-left: 138px;
  margin-top: 70px;
  height: 780px;
  overflow-y: scroll
}

.question-container-custom-closed {
  margin-left: 138px;
  margin-top: 70px;
  height: 780px;
  overflow-y: scroll
}

.answer-content-counter {
  font-size: 16px;
  font-family: Blinker-Regular, serif !important;
  color: #444444;
}

.answer-content-counter * {
  font-size: 16px;
  font-family: Blinker-Regular, serif !important;
  color: #444444;
}

.answer-content-counter > * {
  font-size: 16px;
  font-family: Blinker-Regular, serif !important;
  color: #444444;
}
</style>