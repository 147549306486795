<template>
  <!-- Fucking Calculator Created By Fucking Mind By Fucking Way By Fucking Short Code YO Hossein Tehrani -->
  <div class="p-3" style="max-width: 400px; margin: 50px auto; background: #ffbe66;border-radius: 10px!important">
    <v-icon v-if="closable" @click="close()">mdi-close</v-icon>
    <div class="text-right mr-4">
      {{ calculatorValue || 0 }}
    </div>
    <v-row class="pa-4" style="border-radius: 20px!important">
      <v-col cols="3" class="ma-0" v-for="n in calculatorElements" :key="n">
        <div class="lead text-white text-center m-1 py-3 bg-vue-dark rounded hover-class"
             :class="{'bg-vue-green': ['C','*','/','-','+','%','='].includes(n)}"
             @click="action(n)">
          {{ n }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Calculator',
  props: {
    msg: String,
    closable: Boolean
  },

  data() {
    return {
      calculatorValue: '',
      calculatorElements: ['C', '*', '/', '-', 7, 8, 9, '+', 4, 5, 6, '%', 1, 2, 3, '=', 0, '.'],
      operator: null,
      previousCalculatorValue: '',
    }
  },

  methods: {
    close(){
      this.$emit('closeCalc')
    },
    action(n) {

      /* Append value */
      if (!isNaN(n) || n === '.') {
        this.calculatorValue += n + '';
      }

      /* Clear value */
      if (n === 'C') {
        this.calculatorValue = '';
      }

      /* Percentage */
      if (n === '%') {
        this.calculatorValue = this.calculatorValue / 100 + '';
      }

      /* Operators */
      if (['/', '*', '-', '+'].includes(n)) {
        this.operator = n;
        this.previousCalculatorValue = this.calculatorValue;
        this.calculatorValue = '';
      }

      /* Calculate result using the eval function */
      if (n === '=') {
        this.calculatorValue = eval(
            this.previousCalculatorValue + this.operator + this.calculatorValue
        );
        this.previousCalculatorValue = '';
        this.operator = null;
      }
    }
  }
}
</script>


<style scoped>
.bg-vue-dark {
  background: #ffbe66;
}

.hover-class:hover {
  cursor: pointer;
  background: #ff8f4e;
}

.bg-vue-green {
  background: #34c288;
}
</style>
