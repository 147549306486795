<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div v-if="selectedQuestion">
        <fullscreen v-if="questions && mode === 'BAR'" class="background-main" :fullscreen.sync="fullscreen">
          <v-snackbar
              top
              v-model="snackbar"
          >
            {{ msg }}
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  text

                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <!--    Header-->
          <v-row>
            <v-col cols="12"
                   :class="showSidebar?'background-top-question-bar-opened':'background-top-question-bar-closed'">
              <v-row class="mt-0 pt-0">
                <v-col cols="1" class="text-center">
                  <img :style="fullscreen ? 'cursor: pointer;margin-left: 120px!important' : 'cursor: pointer;'"
                       @click="showSidebar = !showSidebar" src="@/assets/menu.svg" alt="menu"
                       width="18px" height="14px">
                </v-col>
                <v-col cols="1" class="margin-top-negative">
                  <v-row class="mt-1 pt-1">
                    <v-col class="text-top-question text-left">
                      NO: {{ selectedQuestion }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="text-top-question text-left">
                      ID:#{{ questions[selectedQuestion - 1].id }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="margin-top-negative">
                  <v-row class="mt-1 pt-1" v-if="rules">
                    <v-col v-if="rules.type && rules.type === 'time'" class="text-top-question text-left">
                      Mode: &nbsp; Timed
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-top-question text-left" style="margin-top: -11px!important">
                      <img style="margin-right:5px" width="15px" height="17px" src="@/assets/flag.svg" alt="flag">
                      <span>
                                          Mark
                      </span>
                    </v-col>
                    <v-col cols="3" style="margin-top: -8px;margin-left:-45px">
                      <div v-if="questions[selectedQuestion - 1].bookmark">
                        <img style="cursor: pointer;"
                             @click="bookmark('unmark')"
                             height="17px" width="17px"
                             src="@/assets/TickTrue.svg" alt="true">
                      </div>
                      <div v-else>
                        <img @click="bookmark('mark')" style="cursor: pointer;" height="17px" width="17px"
                             src="@/assets/TickFalse.svg" alt="false">
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="1" class="mt-1" @click="calculatorStatus = !calculatorStatus" style="cursor: pointer">
                  <v-row>
                    <v-col>
                      <img height="50px" width="50px" src="@/assets/calc2.svg" alt="calc">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col class="mt-0 pt-0">
                      Calculator
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="mt-1">
                  <v-row>
                    <v-col style="cursor: pointer;">
                      <img @click="showLab = !showLab" height="50px" width="50px" src="@/assets/lab.svg" alt="lab">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col @click="showLab = !showLab" style="cursor: pointer;" class="mt-0 pt-0">
                      Lab Values
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="mt-1">
                  <v-row>
                    <v-col>
                      <img style="cursor: pointer" @click="commentDialog = true" height="50px" width="50px"
                           src="@/assets/msg.svg" alt="notes">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col class="mt-0 pt-0">
                      Notes
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="2" class="text-center">
                  <v-row class="mt-5">
                    <v-col>
                      <v-row>
                        <v-col>
                          <img v-if="mode === 'NBME'" @click="changeMode('NBME')" style="cursor: pointer" width="18px"
                               height="18px"
                               src="@/assets/TickFalseGrey.svg" alt="true">
                          <img v-else @click="changeMode('NBME')" style="cursor: pointer" width="18px" height="18px"
                               src="@/assets/TickFalse.svg" alt="false">
                        </v-col>
                        <v-col>
                          <img v-if="mode === 'BAR'" style="margin-left:-42px;cursor: pointer" width="18px"
                               height="18px" src="@/assets/TickFalseGrey.svg"
                               alt="true">
                          <img v-else @click="changeMode('BAR')" style="margin-left:-42px;cursor: pointer" width="18px"
                               height="18px"
                               src="@/assets/TickFalse.svg" alt="false">
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="5" class="text-top-question text-right mt-0 pt-0">
                      NBME View
                    </v-col>
                    <v-col cols="5" class="text-top-question text-right mt-0 pt-0">
                      BAR View
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="text-left">
                  <v-row class="mt-5">
                    <v-col style="cursor: pointer" @click="fullscreen = !fullscreen">
                      <img src="@/assets/FullScreen.svg" alt="fullscreen" height="20px" width="20px">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col @click="fullscreen = !fullscreen" style="cursor: pointer;margin-left: -25px;"
                           class="mt-0 pt-0 text-top-question">
                      Full Screen
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--    SideBAr-->
          <v-row v-if="showSidebar">
            <v-col class="sidebar-question text-left">
              <v-row>
                <v-col>
                </v-col>
              </v-row>
              <v-row v-for="(question,key,index) in questions" :class="question.id + index" :key="key">
                <v-col class="text-center">
                  <div v-if="key === selectedQuestion - 1">
                    <v-btn class="counter-question-btn" color="#39BF88" elevation="0"
                           style="border-radius: 10px;height:50px!important;width:50px!important">
                  <span
                      style="color:white!important;font-family: Arial, Helvetica, sans-serif!important;font-size: 16px!important">
                                  {{ key + 1 }}
                  </span>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn text @click="selectedQuestion = key + 1" class="counter-question-btn" color="#A8A8A8"
                           style="border-radius: 10px;height:50px!important;width:50px!important">
                  <span style="font-family: Arial, Helvetica, sans-serif!important;font-size: 16px!important">
                                  {{ key + 1 }}
                  </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--    Question-->
          <div v-if="!showSidebar" style="height: 80px!important"></div>
          <v-row :class="showSidebar ? 'question-container-custom-opened' : 'question-container-custom-closed'">
            <v-col v-if="selectedQuestion || selectedQuestion === 0 && selectedQuestion !== null" cols="8"
                   class="text-justify question-title-text">
              <v-row>
                <v-col class="question-title-text" style="margin-top: 70px!important" cols="12"
                       v-html="questions[selectedQuestion - 1].title">
                </v-col>
                <v-col cols="12" v-if="questions[selectedQuestion - 1].image">
                  <img height="200px" width="400px" :src="baseURL+questions[selectedQuestion - 1].image"
                       :alt="questions[selectedQuestion - 1].title">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--    Answers-->
                  <v-row style="margin-left:30px;margin-top:20px;" v-if="selectedQuestion">
                    <v-col cols="12">
                      <v-row style="cursor: pointer;margin-top: -38px!important;" @click="setAnswer(answer)"
                             v-for="(answer,key,index) in questions[selectedQuestion-1].answers" :key="key">
                        <v-col class="text-justify">
                          <v-row>
                            <v-col cols="2">
                              <div
                                  v-if="questionsAndAnswers && answer.id === questionsAndAnswers[questions[selectedQuestion - 1].id]">
                                <img style="margin-top:15px;" height="18px"
                                     width="18px" src="@/assets/TickFalseGrey.svg"
                                     alt="false">
                              </div>
                              <div v-else>
                                <img style="margin-top:15px;" height="18px" width="18px" src="@/assets/TickFalse.svg"
                                     alt="false">
                              </div>
                            </v-col>
                            <v-col cols="10" style="margin-left:-6.8vw;"
                                   class="text-left mt-3">
                              <v-row>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 0 || key === 0">
                                  A .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 1 || key === 1">
                                  B .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 2 || key === 2">
                                  C .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 3 || key === 3">
                                  D .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 4 || key === 4">
                                  E .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 5 || key === 5">
                                  F .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 6 || key === 6">
                                  G .
                                </v-col>
                                <v-col class="answer-content-counter" cols="1" v-if="index === 7 || key === 7">
                                  H .
                                </v-col>
                                <v-col cols="11">
                                  <v-row>
                                    <v-col cols="12" class="question-title-text" style="word-wrap:break-word;">
                                      <div v-html="answer.content" class="answer-content-counter">
                                      </div>
                                    </v-col>
                                    <v-col cols="12" v-if="answer.image && answer.image !== 'null'">
                                      <img height="250px" width="400px"
                                           :src="baseURL+answer.image"
                                           :alt="answer.content ">
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!--    SUBMIT-->
                  <v-row style="margin-left:30px;margin-top:20px;margin-bottom: 110px">
                    <v-col class="text-left">
                      <v-btn @click="onSubmit" style="font-size: 16px!important" class="white--text text-capitalize"
                             elevation="0"
                             color="#48B284" rounded
                             height="50px" width="150px">Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!--    EmptySpace-->
                  <v-row>
                    <v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col style="right:0px;bottom:12vh;z-index: 10000;position:absolute;" cols="4" class="mt-16 pt-16">
              <v-alert
                  transition="fab-transition"
                  class="mt-16"
                  v-model="alertAnswer"
                  dismissible
                  type="success"
                  color="#39bf88"
                  border="bottom"
                  elevation="8"
                  icon="mdi-comment-check-outline"
              >
                <strong>Nice!</strong> &nbsp;&nbsp;
                Your answer has been recorded.
              </v-alert>
            </v-col>
            <v-col cols="4" v-if="showLab" class="lab-container-bar">
              <div @click="showLab = !showLab" class="text-right pr-6" style="cursor: pointer">
                <v-icon>mdi-close</v-icon>
              </div>
              <div v-html="lab">
              </div>
            </v-col>
          </v-row>
          <!--    Footer-->
          <v-row v-if="questions">
            <v-col class="footer-question">
              <v-row class="mt-1">
                <!--          3buttons-->
                <v-col cols="1" class="text-right">
                  <v-row>
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion - 1">
                      <img class="button-hover" width="50px" height="50px" src="@/assets/left.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;" class="text-top-question mt-0 pt-0"
                           @click="selectedQuestion = selectedQuestion - 1">
                      Previous
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-row>
                    <v-col @click="pause()">
                      <img style="cursor: pointer" class="button-hover" width="50px" height="50px"
                           src="@/assets/pause.svg"
                           alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="text-top-question mt-0 pt-0">
                      Pause
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="text-left">
                  <v-row>
                    <v-col v-if="selectedQuestion !== questions.length" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion + 1">
                      <img class="button-hover" width="50px" height="50px" src="@/assets/right.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== questions.length" @click="selectedQuestion = selectedQuestion + 1"
                           class="text-top-question mt-0 pt-0"
                           style="margin-left:0.4vw;cursor: pointer">
                      Next
                    </v-col>
                  </v-row>
                </v-col>
                <!--          emptyCole-->
                <v-col cols="1"></v-col>
                <!--          Clock side-->
                <v-col cols="2">
                  <v-row>
                    <v-col>
                      <img src="@/assets/clock.svg" alt="clock" height="40px" width="40px">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0 text-top-question">
                    <span style="font-size: 25px!important;font-family: Blinker-SemiBold!important">
                                        {{ minutes }}:{{ seconds }}
                    </span>
                    </v-col>
                  </v-row>
                </v-col>
                <!--          emptyCole-->
                <v-col cols="2"></v-col>
                <!--          Report-->
                <v-col cols="1" class="text-right" style="margin-left: 70px!important">
                  <v-row>
                    <v-col>
                      <report :entity_id="questions[selectedQuestion - 1].id" entity_type="question">
                        <template v-slot:button>
                          <img class="button-hover" src="@/assets/menu2.svg" alt="menu2" width="50px" height="50px">
                        </template>
                      </report>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0 mt-0">
                    <v-col class="pt-0 mt-0 text-top-question">
                      Report
                    </v-col>
                  </v-row>
                </v-col>
                <!--          ENdBuTTon-->
                <v-col cols="2" class="text-right">
                  <v-btn @click="endDialog = true" rounded color="#FDBD68" elevation="0"
                         class="text-capitalize white--text mt-2 mr-6" width="150px"
                         height="50px" style="position: absolute;z-index: 2;right: 185px;">
                    End
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </fullscreen>
        <fullscreen v-if="questions && mode === 'NBME'" class="background-main" :fullscreen.sync="fullscreen">
          <v-snackbar
              centered
              v-model="snackbar"
          >
            {{ msg }}
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <!--    Header-->
          <v-row>
            <v-col cols="12"
                   :class="showSidebar ?  'background-top-question-bar-nbme-opened' : 'background-top-question-bar-nbme-closed'">
              <v-row class="mt-0 pt-0">
                <v-col cols="1" class="text-center">
                  <img :style="fullscreen ? 'cursor: pointer;margin-left: 120px!important' : 'cursor: pointer;'"
                       @click="showSidebar = !showSidebar" src="@/assets/NBME/menu.svg" alt="menu"
                       width="18px" height="14px">
                </v-col>
                <v-col cols="1" class="margin-top-negative">
                  <v-row class="mt-1 pt-1">
                    <v-col class="text-top-question-nbme text-left">
                      NO: {{ selectedQuestion }}
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="text-top-question-nbme text-left">
                      ID:#{{ questions[selectedQuestion - 1].id }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="margin-top-negative">
                  <v-row class="mt-1 pt-1" v-if="rules">
                    <v-col class="text-top-question-nbme text-left">
                      Mode: &nbsp; Timed
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-top-question-nbme text-left" style="margin-top: -11px!important">
                      <img style="margin-right:5px" width="15px" height="17px" src="@/assets/flag.svg" alt="flag">
                      <span>
                                          Mark
                      </span>
                    </v-col>
                    <v-col cols="3" style="margin-top: -8px;margin-left:-45px">
                      <div v-if="questions[selectedQuestion - 1].bookmark">
                        <img
                            @click="bookmark('unmark')"
                            style="cursor: pointer;"
                            height="17px" width="17px"
                            src="@/assets/TickTrue.svg" alt="true">
                      </div>
                      <div v-else>
                        <img @click="bookmark('mark')" style="cursor: pointer;" height="17px" width="17px"
                             src="@/assets/TickFalse.svg" alt="false">
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="1" class="mt-1" @click="calculatorStatus = !calculatorStatus" style="cursor: pointer">
                  <v-row>
                    <v-col>
                      <img height="50px" width="50px" src="@/assets/calc2.svg" alt="calc">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question-nbme">
                    <v-col class="mt-0 pt-0">
                      Calculator
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="mt-1">
                  <v-row>
                    <v-col style="cursor: pointer;">
                      <img @click="showLab = !showLab" height="50px" width="50px" src="@/assets/lab.svg" alt="lab">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question-nbme">
                    <v-col @click="showLab = !showLab" style="cursor: pointer;" class="mt-0 pt-0">
                      Lab Values
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="mt-1">
                  <v-row>
                    <v-col>
                      <img style="cursor: pointer" @click="commentDialog = true" height="50px" width="50px"
                           src="@/assets/msg.svg" alt="notes">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question-nbme">
                    <v-col class="mt-0 pt-0">
                      Notes
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="2" class="text-center">
                  <v-row class="mt-5">
                    <v-col>
                      <v-row>
                        <v-col>
                          <img v-if="mode === 'NBME'" @click="changeMode('NBME')" style="cursor: pointer" width="18px"
                               height="18px"
                               src="@/assets/NBME/true.svg" alt="true">
                          <img v-else @click="changeMode('NBME')" style="cursor: pointer" width="18px" height="18px"
                               src="@/assets/NBME/false.svg" alt="false">
                        </v-col>
                        <v-col>
                          <img v-if="mode === 'BAR'" style="margin-left:-42px;cursor: pointer" width="18px"
                               height="18px" src="@/assets/NBME/true.svg"
                               alt="true">
                          <img v-else @click="changeMode('BAR')" style="margin-left:-42px;cursor: pointer" width="18px"
                               height="18px"
                               src="@/assets/NBME/false.svg" alt="false">
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col cols="5" class="text-top-question-nbme text-right mt-0 pt-0">
                      NBME View
                    </v-col>
                    <v-col cols="5" class="text-top-question-nbme text-right mt-0 pt-0">
                      BAR View
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="text-left">
                  <v-row class="mt-5">
                    <v-col style="cursor: pointer" @click="fullscreen = !fullscreen">
                      <img src="@/assets/NBME/fullscreen.svg" alt="fullscreen" height="20px" width="20px">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col @click="fullscreen = !fullscreen" style="cursor: pointer;margin-left: -25px;"
                           class="mt-0 pt-0 text-top-question-nbme">
                      Full Screen
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--    SideBAr-->
          <v-row v-if="showSidebar">
            <v-col class="sidebar-question text-left">
              <v-row>
                <v-col>
                </v-col>
              </v-row>
              <v-row v-for="(question,key,index) in questions" :class="question.id + index" :key="key">
                <v-col class="text-center">
                  <div v-if="key === selectedQuestion - 1">
                    <v-btn class="counter-question-btn" color="#3C549F" elevation="0"
                           style="border-radius: 10px;height:50px!important;width:50px!important">
                  <span
                      style="color:white!important;font-family: Arial, Helvetica, sans-serif!important;font-size: 16px!important;">
                                  {{ key + 1 }}
                  </span>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn text @click="selectedQuestion = key + 1" class="counter-question-btn" color="#A8A8A8"
                           style="border-radius: 10px;height:50px!important;width:50px!important">
                  <span style="font-family: Arial, Helvetica, sans-serif!important;font-size: 16px!important">
                                  {{ key + 1 }}
                  </span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div>
            <!--    Question-->
            <div v-if="!showSidebar" style="height: 80px!important"></div>
            <v-row :class="showSidebar ? 'question-container-custom-opened' : 'question-container-custom-closed'">
              <v-col v-if="selectedQuestion || selectedQuestion === 0 && selectedQuestion !== null" cols="8"
                     class="text-justify question-title-text">
                <v-row>
                  <v-col class="question-title-text" style="margin-top: 15px!important" cols="12"
                         v-html="questions[selectedQuestion - 1].title">
                  </v-col>
                  <v-col cols="12" v-if="questions[selectedQuestion - 1].image">
                    <img height="200px" width="400px" :src="baseURL+questions[selectedQuestion - 1].image"
                         :alt="questions[selectedQuestion - 1].title">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <!--    Answers-->
                    <v-row style="margin-left:30px;margin-top:20px;" v-if="selectedQuestion">
                      <v-col cols="12">
                        <v-row style="cursor: pointer;margin-top: -38px!important;" @click="setAnswer(answer)"
                               v-for="(answer,key,index) in questions[selectedQuestion-1].answers" :key="key">
                          <v-col class="text-justify">
                            <v-row>
                              <v-col cols="2">
                                <div
                                    v-if="questionsAndAnswers && answer.id === questionsAndAnswers[questions[selectedQuestion - 1].id]">
                                  <img style="margin-top:15px;" height="18px"
                                       width="18px" src="@/assets/TickFalseGrey.svg"
                                       alt="false">
                                </div>
                                <div v-else>
                                  <img style="margin-top:15px;" height="18px" width="18px" src="@/assets/TickFalse.svg"
                                       alt="false">
                                </div>
                              </v-col>
                              <v-col cols="10" style="margin-left:-6.8vw;"
                                     class="text-left mt-3">
                                <v-row>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 0 || key === 0">
                                    A .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 1 || key === 1">
                                    B .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 2 || key === 2">
                                    C .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 3 || key === 3">
                                    D .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 4 || key === 4">
                                    E .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 5 || key === 5">
                                    F .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 6 || key === 6">
                                    G .
                                  </v-col>
                                  <v-col class="answer-content-counter" cols="1" v-if="index === 7 || key === 7">
                                    H .
                                  </v-col>
                                  <v-col cols="11">
                                    <v-row>
                                      <v-col cols="12" class="question-title-text" style="word-wrap:break-word;">
                                        <div v-html="answer.content" class="answer-content-counter">
                                        </div>
                                      </v-col>
                                      <v-col cols="12" v-if="answer.image && answer.image !== 'null'">
                                        <img height="250px" width="400px"
                                             :src="baseURL+answer.image"
                                             :alt="answer.content ">
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <!--    SUBMIT-->
                    <v-row style="margin-left:30px;margin-top:20px;margin-bottom: 110px">
                      <v-col class="text-left">
                        <v-btn @click="onSubmit()" style="font-size: 16px!important" class="white--text text-capitalize"
                               color="#3C549F" rounded elevation="0"
                               height="50px" width="150px">Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!--    EmptySpace-->
                    <v-row>
                      <v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col style="right:0;bottom:12vh;z-index: 10000;position:absolute;" cols="4" class="mt-16 pt-16">
                <v-alert
                    transition="fab-transition"
                    class="mt-16"
                    v-model="alertAnswer"
                    dismissible
                    type="success"
                    color="#3c549f"
                    border="bottom"
                    elevation="8"
                    icon="mdi-comment-check-outline"
                >
                  <strong>Nice!</strong> &nbsp;&nbsp;
                  Your answer has been recorded.
                </v-alert>
              </v-col>
              <v-col cols="4" v-if="showLab" class="lab-container-nbme">
                <div @click="showLab = !showLab" class="text-right pr-6" style="cursor: pointer">
                  <v-icon>mdi-close</v-icon>
                </div>
                <div v-html="lab">
                </div>
              </v-col>
            </v-row>
          </div>
          <!--    Footer-->
          <v-row v-if="questions">
            <v-col class="footer-question-nbme">
              <v-row class="mt-1">
                <!--          3buttons-->
                <v-col cols="1" class="text-right">
                  <v-row>
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion - 1">
                      <img class="button-hover" width="50px" height="50px" src="@/assets/NBME/left.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;"
                           class="text-top-question-nbme mt-0 pt-0"
                           @click="selectedQuestion = selectedQuestion - 1">
                      Previous
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1">
                  <v-row>
                    <v-col @click="pause()">
                      <img class="button-hover" width="50px" height="50px" src="@/assets/NBME/pause.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="text-top-question-nbme mt-0 pt-0">
                      Pause
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="text-left">
                  <v-row>
                    <v-col v-if="selectedQuestion !== questions.length" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion + 1">
                      <img class="button-hover" width="50px" height="50px" src="@/assets/NBME/right.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== questions.length" @click="selectedQuestion = selectedQuestion + 1"
                           class="text-top-question-nbme mt-0 pt-0"
                           style="margin-left:0.4vw;cursor: pointer">
                      Next
                    </v-col>
                  </v-row>
                </v-col>
                <!--          emptyCole-->
                <v-col cols="1"></v-col>
                <!--          Clock side-->
                <v-col cols="2">
                  <v-row>
                    <v-col>
                      <img src="@/assets/NBME/clock.svg" alt="clock" height="40px" width="40px">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0 text-top-question-nbme">
                    <span style="font-size: 25px!important;font-family: Blinker-SemiBold!important">
                                        {{ minutes }}:{{ seconds }}
                    </span>
                    </v-col>
                  </v-row>
                </v-col>
                <!--          emptyCole-->
                <v-col cols="2"></v-col>
                <!--          Report-->
                <v-col cols="1" class="text-right" style="margin-left: 70px!important">
                  <v-row>
                    <v-col>
                      <report :entity_id="questions[selectedQuestion - 1].id" entity_type="question">
                        <template v-slot:button>
                          <img class="button-hover" src="@/assets/NBME/report.svg" alt="menu" width="50px"
                               height="50px">
                        </template>
                      </report>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0 mt-0">
                    <v-col class="pt-0 mt-0 text-top-question-nbme">
                      Report
                    </v-col>
                  </v-row>
                </v-col>
                <!--          ENdBuTTon-->
                <v-col cols="2" class="text-right">
                  <v-btn @click="endDialog = true" rounded color="#FDBD68" elevation="0" class="text-capitalize mt-2"
                         width="150px"
                         height="50px" style="color:#3C549F;position: absolute;z-index: 2;right: 185px;">
                    End
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </fullscreen>
        <div class="text-center">
          <!--      FINISH EXAM-->
          <v-dialog
              v-model="endDialog"
              width="800px"
          >
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="10">
                <v-card color="#F2F3F7" class="pa-4" style="border-radius: 10px!important;">
                  <v-card-text class="text-top-question"
                               style="border-color:#A8A8A8;border-style: solid;border-radius: 10px;border-width:1px!important;padding:1.9vw!important;">
                    <v-row>
                      <v-col>
                        Are you sure? <br>
                        This will end the test and you can not change your answers after that!
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-right">
                        <v-btn @click="endTest()" elevation="0" class="white--text" rounded width="220px" height="60px"
                               color="#48B284">
                          <span>
                            Yes
                          </span>
                          <span class="text-capitalize">
                            ,end the test!
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col class="text-left">
                        <v-btn @click="endDialog = false" elevation="0" class="white--text" rounded width="220px"
                               height="60px" color="#FDBD68">
                          <span>
                            NO
                          </span>
                          <span class="text-capitalize">
                            ,return to test!
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
                <img style="cursor: pointer" @click="endDialog = false" src="@/assets/close.svg" alt="close"
                     width="50px"
                     height="50px">
              </v-col>
            </v-row>
          </v-dialog>
          <!--      Comment-->
          <v-dialog
              v-model="commentDialog"
              width="800px"
          >
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="10">
                <v-card color="#F2F3F7" class="pa-4" style="border-radius: 10px!important;">
                  <v-card-text class="text-top-question">
                    <v-row>
                      <v-col>
                        <v-text-field
                            color="#39bf88"
                            label="Title"
                            v-model="title">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                            v-model="body"
                            label="Comment"
                            outlined
                            color="#39bf88"
                            rows="10">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-btn class="text-capitalize mx-8"
                               style="color:white;font-size:16px; box-shadow: none!important;"
                               height="60px" width="210px" rounded color="#fdbd68" @click="commentDialog = false">Cancel
                        </v-btn>
                        <v-btn class="text-capitalize mx-8"
                               style="color:white;font-size:16px; box-shadow: none!important;"
                               elevation="0" height="60px" width="210px" rounded color="#39bf88"
                               @click="storeComment()">
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
                <img style="cursor: pointer" @click="commentDialog = false" src="@/assets/close.svg" alt="close"
                     width="50px"
                     height="50px">
              </v-col>
            </v-row>
          </v-dialog>
          <calculator style="position: absolute;z-index: 2000;top: 10vh;right: 1vw;"
                      v-if="calculatorStatus"></calculator>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="selectedQuestion">
        <div v-if="questions && mode === 'BAR'" class="background-main">
          <v-snackbar
              top
              v-model="snackbar"
          >
            {{ msg }}
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="pink"
                  text

                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
          <!--    Header-->
          <v-row >
            <v-col cols="12"
                   class="background-top-question-bar-closed" style="min-height: 20vh!important;">
              <v-row class="mt-0 pt-0">
                <v-col cols="8" class="text-left" style="color: #316b4f!important;font-size:13px ">
                  NO: {{ selectedQuestion }} &nbsp; | &nbsp;Total : {{ questions.length }} &nbsp; | &nbsp; ID:#{{ questions[selectedQuestion - 1].id }}&nbsp; | &nbsp; Mode: Timed
                </v-col>
                <v-col cols="4" class="text-left">
                  <img width="15px" height="14px" src="@/assets/flag.svg" alt="flag">
                  <span style="color: #797979!important;font-size:13px ">
                                          Mark
                      </span>
                  <span v-if="questions[selectedQuestion - 1].bookmark">
                    <img style="cursor: pointer;margin-top: 5px!important;"
                         @click="bookmark('unmark')"
                         height="14px" width="14px"
                         src="@/assets/TickTrue.svg" alt="true">
                  </span>
                  <span v-else>
                    <img @click="bookmark('mark')"
                         style="cursor: pointer;"
                         height="14px" width="14px"
                         src="@/assets/TickFalse.svg" alt="false">
                  </span>
                </v-col>
                <v-col cols="4" class="mt-0 pt-0" @click="calculatorStatus = !calculatorStatus" style="cursor: pointer">
                  <v-row class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0">
                      <img height="30px" width="30px" src="@/assets/calc2.svg" alt="calc">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col class="mt-0 pt-0">
                      Calculator
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="mt-0 pt-0">
                  <v-row class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0" style="cursor: pointer;">
                      <img @click="showLab = !showLab" height="30px" width="30px" src="@/assets/lab.svg" alt="lab">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col @click="showLab = !showLab" style="cursor: pointer;" class="mt-0 pt-0">
                      Lab Values
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="mt-0 pt-0">
                  <v-row class="pt-0 mt-0">
                    <v-col class="mt-0 pt-0">
                      <img style="cursor: pointer" @click="commentDialog = true" height="30px" width="30px"
                           src="@/assets/msg.svg" alt="notes">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0 text-top-question">
                    <v-col class="mt-0 pt-0">
                      Notes
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--    Question-->
          <v-row style="margin-top: 150px!important;margin-left: 10px;margin-right: 10px">
            <v-col v-if="selectedQuestion || selectedQuestion === 0 && selectedQuestion !== null" cols="12"
                   class="text-justify question-title-text">
              <v-row>
                <v-col class="question-title-text" style="margin-top: 70px!important" cols="12"
                       v-html="questions[selectedQuestion - 1].title">
                </v-col>
                <v-col cols="12" v-if="questions[selectedQuestion - 1].image">
                  <img height="150px" width="300px" :src="baseURL+questions[selectedQuestion - 1].image"
                       :alt="questions[selectedQuestion - 1].title">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--    Answers-->
                  <v-row style="margin-top:20px;" v-if="selectedQuestion">
                    <v-col cols="12">
                      <v-row style="cursor: pointer;margin-top: -38px!important;" @click="setAnswer(answer)"
                             v-for="(answer,key,index) in questions[selectedQuestion-1].answers" :key="key">
                        <v-col class="text-justify">
                          <v-row>
                            <v-col cols="2">
                              <div
                                  v-if="questionsAndAnswers && answer.id === questionsAndAnswers[questions[selectedQuestion - 1].id]">
                                <img style="margin-top:15px;" height="18px"
                                     width="18px" src="@/assets/TickFalseGrey.svg"
                                     alt="false">
                              </div>
                              <div v-else>
                                <img style="margin-top:15px;" height="18px" width="18px" src="@/assets/TickFalse.svg"
                                     alt="false">
                              </div>
                            </v-col>
                            <v-col cols="10" style="margin-left:-6.8vw;"
                                   class="text-left mt-3">
                              <v-row>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 0 || key === 0">
                                  A .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 1 || key === 1">
                                  B .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 2 || key === 2">
                                  C .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 3 || key === 3">
                                  D .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 4 || key === 4">
                                  E .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 5 || key === 5">
                                  F .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 6 || key === 6">
                                  G .
                                </v-col>
                                <v-col class="answer-content-counter" cols="2" v-if="index === 7 || key === 7">
                                  H .
                                </v-col>
                                <v-col cols="10" style="margin-left: -22px">
                                  <v-row>
                                    <v-col cols="12" class="question-title-text" style="word-wrap:break-word;">
                                      <div v-html="answer.content" class="answer-content-counter">
                                      </div>
                                    </v-col>
                                    <v-col cols="12" v-if="answer.image && answer.image !== 'null'">
                                      <img height="175px" width="300px"
                                           :src="baseURL+answer.image"
                                           :alt="answer.content ">
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!--    SUBMIT-->
                  <v-row style="margin-top:20px;margin-bottom: 200px">
                    <v-col class="text-left" cols="6">
                      <v-btn @click="onSubmit" style="font-size: 16px!important" class="white--text text-capitalize"
                             elevation="0"
                             color="#48B284" rounded
                             height="40px" width="120px">Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!--    EmptySpace-->
                  <v-row>
                    <v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col style="right:0px;bottom:12vh;z-index: 10000;position:absolute;" cols="12" class="mt-16 pt-16">
              <v-alert
                  transition="fab-transition"
                  class="mt-16"
                  v-model="alertAnswer"
                  dismissible
                  type="success"
                  color="#39bf88"
                  border="bottom"
                  elevation="8"
                  icon="mdi-comment-check-outline"
              >
                <strong>Nice!</strong> &nbsp;&nbsp;
                Your answer has been recorded.
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="showLab" class="lab-container-bar" style="position: absolute;top:15vh;left:0;max-width: 100vw">
              <div @click="showLab = !showLab" class="text-right" style="cursor: pointer">
                <v-icon>mdi-close</v-icon>
              </div>
              <div v-html="lab">
              </div>
            </v-col>
          </v-row>
          <!--    Footer-->
          <v-row v-if="questions" class="mb-0"
                 style="min-width: 103vw!important;background-color: #f2f3f7;position: absolute;bottom: -10px;z-index: 10000">
            <v-col cols="12" style="font-family: Blinker-Bold;font-size: 19px;margin-bottom: -35px;margin-top: -15px;" class="text-top-question">
              {{ selectedQuestion }}/{{ questions.length }}
            </v-col>
            <v-col class="text-left mb-0">
              <v-row class="mt-1">
                <!--          3buttons-->
                <v-col cols="2" class="text-right pl-5">
                  <v-row>
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion - 1">
                      <img class="button-hover" width="40px" height="40px" src="@/assets/left.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== 1" style="cursor: pointer;color: #a8a8a8;font-size: 12px"
                           class="mt-0 pt-0"
                           @click="selectedQuestion = selectedQuestion - 1">
                      Previous
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" style="margin-left: -10px">
                  <v-row>
                    <v-col @click="pause()">
                      <img style="cursor: pointer;" class="button-hover" width="40px" height="40px"
                           src="@/assets/pause.svg"
                           alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col style="color: #a8a8a8;font-size: 12px" class=" mt-0 pt-0">
                      Pause
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" style="margin-left: -15px">
                  <v-row>
                    <v-col v-if="selectedQuestion !== questions.length" style="cursor: pointer;"
                           @click="selectedQuestion = selectedQuestion + 1">
                      <img class="button-hover" width="40px" height="40px" src="@/assets/right.svg" alt="left">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col v-if="selectedQuestion !== questions.length" @click="selectedQuestion = selectedQuestion + 1"
                           class=" mt-0 pt-0"
                           style=";cursor: pointer;color: #a8a8a8;font-size: 12px">
                      Next
                    </v-col>
                  </v-row>
                </v-col>
                <!--          Clock side-->
                <v-col cols="2">
                  <v-row>
                    <v-col>
                      <img src="@/assets/clock.svg" alt="clock" height="30px" width="30px">
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="mt-0 pt-0 text-top-question">
                    <span style="font-size: 20px!important;font-family: Blinker-SemiBold!important">
                                        {{ minutes }}:{{ seconds }}
                    </span>
                    </v-col>
                  </v-row>
                </v-col>
                <!--          Report-->
                <v-col cols="2" class="text-left" style="">
                  <v-row>
                    <v-col>
                      <report :entity_id="questions[selectedQuestion - 1].id" entity_type="question">
                        <template v-slot:button>
                          <img class="button-hover" src="@/assets/menu2.svg" alt="menu2" width="40px" height="40px">
                        </template>
                      </report>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0 mt-0">
                    <v-col class="pt-0 mt-0" style="cursor: pointer;color: #a8a8a8;font-size: 12px">
                      Report
                    </v-col>
                  </v-row>
                </v-col>
                <!--          ENdBuTTon-->
                <v-col cols="2" class="text-right">
                  <v-btn @click="endDialog = true" rounded color="#FDBD68" elevation="0"
                         class="text-capitalize white--text mt-2 mr-6" width="70px"
                         height="30px">
                    End
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <!--      FINISH EXAM-->
          <v-dialog
              v-model="endDialog"
              width="95%"
          >
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="10">
                <v-card color="#F2F3F7" class="pa-1" style="border-radius: 10px!important;">
                  <v-card-text class="text-top-question"
                               style="border-color:#A8A8A8;border-style: solid;border-radius: 10px;border-width:1px!important;padding:1.9vw!important;">
                    <v-row>
                      <v-col>
                        Are you sure? <br>
                        This will end the test and you can not change your answers after that!
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="text-center" cols="6">
                        <v-btn @click="endTest()" style="font-size: 12px!important;" elevation="0" class="white--text" rounded width="130px" height="40px"
                               color="#48B284">
                          <span>
                            Yes
                          </span>
                          <span class="text-capitalize">
                            ,end the test!
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col class="text-center pr-16" cols="6">
                        <v-btn @click="endDialog = false" style="font-size: 12px!important;" elevation="0" class="white--text" rounded width="130px"
                               height="40px" color="#FDBD68">
                          <span>
                            NO
                          </span>
                          <span class="text-capitalize">
                            ,return to test!
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
                <img style="cursor: pointer" @click="endDialog = false" src="@/assets/close.svg" alt="close" width="50px"
                     height="50px">
              </v-col>
            </v-row>
          </v-dialog>
          <!--      Comment-->
          <v-dialog
              v-model="commentDialog"
              width="100%"
          >
            <v-row class="ma-0 pa-0">
              <v-col class="ma-0 pa-0" cols="10">
                <v-card color="#F2F3F7" class="pa-4" style="border-radius: 10px!important;">
                  <v-card-text class="text-top-question">
                    <v-row>
                      <v-col>
                        <v-text-field
                            color="#39bf88"
                            label="Title"
                            v-model="title">
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                            v-model="body"
                            label="Comment"
                            outlined
                            color="#39bf88"
                            rows="10">
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="text-left">
                        <v-btn class="text-capitalize"
                               style="color:white;font-size:16px; box-shadow: none!important;"
                               height="50px" width="100px" rounded color="#fdbd68" @click="commentDialog = false">Cancel
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn class="text-capitalize"
                               style="color:white;font-size:16px; box-shadow: none!important;"
                               elevation="0" height="50px" width="100px" rounded color="#39bf88"
                               @click="storeComment()">
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="ma-0 pa-0" cols="2" style="background-color: transparent!important;">
                <img style="cursor: pointer" @click="commentDialog = false" src="@/assets/close.svg" alt="close"
                     width="50px"
                     height="50px">
              </v-col>
            </v-row>
          </v-dialog>
          <calculator style="position: absolute;z-index: 2000;top:18vh;right: 1vw;"
                      v-if="calculatorStatus" :closable="true" @closeCalc="calculatorStatus = false"></calculator>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Report from "@/components/Report";
import Calculator from "../../Calculator";

export default {
  name: "TimedExam",
  props: ['questions', 'totalTime', 'lab', 'mode', 'rules'],
  components: {
    Calculator,
    Report
  },
  data() {
    return {
      screenType: null,
      calculatorStatus: false,
      pauseCondition: false,
      timeFunction: null,
      title: null,
      body: null,
      commentDialog: false,
      showLab: false,
      endDialog: false,
      alertAnswer: false,
      snackbar: null,
      msg: null,
      showSidebar: true,
      fullscreen: false,
      minutes: null,
      seconds: null,
      selectedQuestion: null,
      questionsAndAnswers: {},
      questionsCondition: {},
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    pause() {
      if (this.pauseCondition) {
        const self = this;
        this.pauseCondition = false
        this.timeFunction = window.setInterval(function () {
          if (self.seconds === 0 && self.minutes !== 0) {
            self.seconds = 60
            self.minutes--
          }
          if (self.minutes !== 0 || self.seconds !== 0) {
            self.seconds--
          } else {
            self.examFinished = true
            self.endTest()
            clearInterval(this.timeFunction);
          }
        }, 1000);
      } else {
        this.pauseCondition = true
        clearInterval(this.timeFunction);
      }

    },
    endTest() {
      this.storeExam()
    },
    storeExam() {
      window.axios.post('api/exam/store', {
        type: this.rules.type,
        questions: this.questions.length,
        questionsAndAnswers: this.questionsAndAnswers
      }).then((res) => {
        this.$router.push({
          name: 'QuestionsFinished',
          params: {
            exam: res.data.data
          }
        })
      })
    },
    storeComment() {
      window.axios.post('api/exam/comment', {
        title: this.title,
        body: this.body,
        questionId: this.selectedQuestion
      }).then(() => {
        this.msg = 'Your comment  on "#' + this.questions[this.selectedQuestion - 1].id + '" question stored successfully'
        this.commentDialog = false
        this.snackbar = true
      }).catch(() => {
        this.msg = 'Your comment does not stored check inputs'
        this.commentDialog = false
        this.snackbar = true
      })
    },
    bookmark(type) {
      window.axios.get('api/exam/mark/' + type + '/' + this.questions[this.selectedQuestion - 1].id).then(() => {
        if (type === 'mark') {
          this.questions[this.selectedQuestion - 1].bookmark = true
        }
        if (type === 'unmark') {
          this.questions[this.selectedQuestion - 1].bookmark = false
        }
      })
    },
    changeMode(mode) {
      this.$emit('changeMode', mode)
    },
    setAnswer(answer) {
      this.$set(this.questionsAndAnswers, this.questions[this.selectedQuestion - 1].id, answer.id)
      window.axios.post('/api/exam/answers', {
        answer: {
          selected_box: answer.id,
          question_id: this.questions[this.selectedQuestion - 1].id,
        }
      }).then((res) => {
        if (res.data.data.condition) {
          this.$set(this.questionsCondition, this.questions[this.selectedQuestion - 1].id, 'true')
        } else {
          this.$set(this.questionsCondition, this.questions[this.selectedQuestion - 1].id, 'false')
        }
      })
    },
    onSubmit() {
      this.alertAnswer = true
      this.setOffAlert()
    },
    setOffAlert() {
      const self = this;
      const timeOut = setTimeout(() => {
        self.alertAnswer = false
        clearTimeout(timeOut);
      }, 4000)
    },
  },
  mounted() {
    this.getScreenType()
    this.selectedQuestion = 1;
    this.seconds = this.totalTime.seconds
    this.minutes = this.totalTime.minutes
    const self = this;
    this.timeFunction = window.setInterval(function () {
      if (self.seconds === 0 && self.minutes !== 0) {
        self.seconds = 60
        self.minutes--
      }
      if (self.minutes !== 0 || self.seconds !== 0) {
        self.seconds--
      } else {
        self.examFinished = true
        self.endTest()
        clearInterval(this.timeFunction);
      }
    }, 1000);
  }
}
</script>

<style scoped>

</style>